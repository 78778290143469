import * as React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import AdminSystemAdminLayout from "../../../components/admin/admin/adminSystemAdminLayout"
import { ThemeProvider } from "@material-ui/core/styles"
import handleError from "../../../helperFunc/handleError"

import AdminSystemAuthPortal from "../../../components/auth/AdminSystemAuthPortal"
import StockMain from "../../../components/admin/admin/stockMain"
import {
  refreshClosedStoreSettings,
  setKitchenSettings,
} from "../../../contexts/actions"
import {
  UserContext,
  StoreSettingsContext,
  FeedbackContext,
  KitchenSettingsContext,
} from "../../../contexts"

import darkTheme from "../../../components/ui/darkTheme"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.1875rem",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0,0,0,.5)",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    "*::-webkit-scrollbar-thumb": {
      marginTop: "1rem",
      marginBottom: "1rem",
      backgroundColor: "#ffa500",
    },
  },
}))

const Stock = () => {
  const [loading, setLoading] = React.useState(true)
  const { user, dispatchUser } = React.useContext(UserContext)
  const { feedback, dispatchFeedback } = React.useContext(FeedbackContext)
  const { kitchenSettings, dispatchKitchenSettings } = React.useContext(
    KitchenSettingsContext
  )

  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  const classes = useStyles()

  const isUserAut =
    user.jwt && user?.role?.name === "KitchenAdmin" && user.onboarding

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  React.useEffect(() => {
    if (isUserAut) {
      axios
        .get(
          process.env.GATSBY_STRAPI_URL +
            "/api/kitchen-setting/get-all-kitchen-setting",
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          //console.log(response.data)
          dispatchKitchenSettings(setKitchenSettings(response.data))
          setLoading(false)
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          handleError(error, dispatchFeedback)
        })
    }
  }, [dispatchKitchenSettings, isUserAut, user.jwt, dispatchFeedback])

  return (
    <ThemeProvider theme={darkTheme}>
      <AdminSystemAdminLayout
        user={user}
        isUserAut={isUserAut}
        page={"stock"}
        footerOne={false}
        footerTwo={false}
        adminPanel={true}
        kitchenSettings={kitchenSettings}
        dispatchFeedback={dispatchFeedback}
        dispatchKitchenSettings={dispatchKitchenSettings}
      >
        {isUserAut ? (
          loading ? (
            <Box classes={{ root: classes.outBox }}>
              <Box classes={{ root: classes.inBox }}>
                <CircularProgress color="primary" />
              </Box>
            </Box>
          ) : (
            <StockMain
              user={user}
              storeSettings={storeSettings}
              dispatchStoreSettings={dispatchStoreSettings}
              feedback={feedback}
              dispatchFeedback={dispatchFeedback}
              kitchenSettings={kitchenSettings}
              dispatchKitchenSettings={dispatchKitchenSettings}
            />
          )
        ) : (
          <AdminSystemAuthPortal
            dispatchFeedback={dispatchFeedback}
            dispatchUser={dispatchUser}
          />
        )}
      </AdminSystemAdminLayout>
    </ThemeProvider>
  )
}

export default Stock
