import * as React from "react"
import axios from "axios"
import StockMainInCont from "./stockMainInCont"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "55%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const StockMain = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  storeSettings,
}) => {
  const [loading, setLoading] = React.useState(true)
  const [products, setProducts] = React.useState([])

  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(process.env.GATSBY_STRAPI_URL + "/api/section/get-all-stock", {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      })
      .then(response => {
        setLoading(false)

        //////////////////////TEMP ORDER FIX
        let productsCopy = [...response.data]
        productsCopy.sort(function (a, b) {
          return parseInt(a.id) - parseInt(b.id)
        })
        for (let i = 0; i < productsCopy.length; i++) {
          productsCopy[i].sharedFields.sort(function (a, b) {
            return parseInt(a.id) - parseInt(b.id)
          })
          for (let j = 0; j < productsCopy[i].sharedFields.length; j++) {
            productsCopy[i].sharedFields[j].optionsData.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })
          }
        }
        //////////////////////TEMP ORDER FIX

        setProducts(productsCopy)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }, [dispatchFeedback, user.jwt])

  return (
    <>
      {loading || products.length < 1 ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      ) : (
        <StockMainInCont
          user={user}
          kitchenSettings={kitchenSettings}
          dispatchFeedback={dispatchFeedback}
          storeSettings={storeSettings}
          products={products}
          setProducts={setProducts}
        />
      )}
    </>
  )
}

export default StockMain
